<template>
  <div>
    <patient-edit />
    <form-medication />
  </div>
</template>

<script>
import patientEdit from '../Patient-edit.vue'
import formMedication from '../../form/medication/Form-medication-medication.vue'

export default ({
  components: {
    patientEdit,
    formMedication,
  },

})
</script>
